import React, { useEffect, useState } from "react";

import ButtonComp from "../../../common/elements/Button";

import GenericCarousel from "../../../common/elements/GenericCarousel";
import {
  StyledHeroContainer,
  StyledHeroCenterContainer,
  StyledHeroBannerText,
  StyledHeroBannerSubText,
  StyledHeroButtonsContainer,
  StyledArrowDownContainer,
  StyledCarouselContainer,
} from "./StyledCommon";
import ArrowDown from "../Static/Icons/Arrow";
import { useDialog } from "../../../common/elements/Dialog/Dialog";
import SignUpDialog, { SignUpModalOptions } from "../Dialogs/SignUp";
import SignUpFormDialog, {
  SignUpFormDialogOptions,
} from "../Dialogs/SignUpForm";
import { useMediaQuery } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toggleLoginPopup } from "../Utils";
import { StyledModal } from "../../../common/materials/GenericStyleComponent";
import ZipangAdvModal from "../Dialogs/ZipangAdvModal";

const StyledImageContainer = styled.img<{ isMobile: boolean }>`
  width: 100%;
  height: ${(props) => (props.isMobile ? "70vh" : "590px")};
`;

const StyledDiv = styled.div<{ isMobile: boolean }>`
  height: ${(props) => (props.isMobile ? "70vh" : "590px")};
`;

const StyledImage = ({
  src = "",
  alt = "carouselstyledimage",
  mobileSrc = "",
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <StyledDiv isMobile={isMobile}>
      <StyledImageContainer
        isMobile={isMobile}
        src={isMobile ? mobileSrc : src}
        alt={alt}
      />
    </StyledDiv>
  );
};

const heroImages = [
  {
    alt: "hero image #1",
    src: "https://ds29nc3ljdjce.cloudfront.net/header-4.png",
    mobileSrc: "https://ds29nc3ljdjce.cloudfront.net/header-4-mobile.png",
  },
  {
    alt: "hero image #1",
    src: "https://ds29nc3ljdjce.cloudfront.net/MyAuctions_hero.jpg",
    mobileSrc: "https://ds29nc3ljdjce.cloudfront.net/header-1-mobile.png",
  },
  {
    alt: "hero image #1",
    src: "https://ds29nc3ljdjce.cloudfront.net/header-2.png",
    mobileSrc: "https://ds29nc3ljdjce.cloudfront.net/header-2-mobile.png",
  },
  {
    alt: "hero image #1",
    src: "https://ds29nc3ljdjce.cloudfront.net/header-3.png",
    mobileSrc: "https://ds29nc3ljdjce.cloudfront.net/header-3-mobile.png",
  },
];

const LandingHero = ({
  isMobile,
  inViewRef,
}: {
  isMobile: boolean;
  inViewRef: React.Ref<any>;
}) => {
  const { t } = useTranslation();
  const [createDialog, closeDialog] = useDialog();
  const [slideNumber, setSlideNumber] = useState(0);
  const [advDialog, setCloseDialog] = useState(false);

  const slideConfig = {
    0: {
      heading1: t("common:hero:3:heading1"),
      heading2: t("common:hero:3:heading2"),
      textAlign: "right",
      buttonAlign: "flex-end",
      subHeading: t("common:hero:3:subHeading"),
      showButtons: false,
    },
    1: {
      heading1: t("common:hero:0:heading1"),
      heading2: t("common:hero:0:heading2"),
      textAlign: "center",
      buttonAlign: "center",
      subHeading: t("common:hero:0:subHeading"),
      showButtons: true,
    },
    2: {
      heading1: t("common:hero:1:heading1"),
      heading2: t("common:hero:1:heading2"),
      textAlign: "left",
      buttonAlign: "flex-start",
      subHeading: t("common:hero:1:subHeading"),
      showButtons: false,
    },
    3: {
      heading1: t("common:hero:2:heading1"),
      heading2: t("common:hero:2:heading2"),
      textAlign: "right",
      buttonAlign: "flex-end",
      subHeading: t("common:hero:2:subHeading"),
      showButtons: false,
    },
  } as { [key: number]: any };

  const history = useHistory();

  const handleSellDiamonds = React.useCallback(() => {
    history.push("/sell");
  }, []);

  const handleBuyDiamonds = React.useCallback(() => {
    const createSingUpDialog = () => {
      createDialog({
        children: (
          <SignUpFormDialog
            onSignUp={closeDialog}
            close={closeDialog}
            isMobile={isMobile}
          />
        ),
        ...SignUpFormDialogOptions,
      });
    };
    const handleSignUp = () => {
      closeDialog();
      requestAnimationFrame(createSingUpDialog);
    };
    createDialog({
      children: <SignUpDialog onSignUp={handleSignUp} close={closeDialog} />,
      ...SignUpModalOptions,
    });
  }, []);

  const handleCarouselSlideChange = (slideNumber: number) =>
    setSlideNumber(slideNumber);

  useEffect(() => {
    trialModalHandler();
  }, []);

  const trialModalHandler = () => {
    let modalDisplayed = localStorage.getItem("modalDisplayed");
    !modalDisplayed &&
      setTimeout(() => {
        localStorage.setItem("modalDisplayed", "1");
        handleBuyDiamonds();
      }, 4000);
  };
  const slide = slideConfig[slideNumber];

  const handleGoToDown = () => {
    let inView = document.getElementById("liveAuction");
    if (inView === null) {
      inView = document.getElementById("howItWorks");
    }
    if (!inView) {
      return;
    }
    const headerOffset = 60;
    const elementPosition = inView.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handleClick = () => {
    history.push("/createfreewebsite");
  };

  const handleAdvModal = () => {
    return (
      <StyledModal
        open={advDialog}
        onClose={() => {
          setCloseDialog(false);
          trialModalHandler();
        }}
        className="handleAdvModal"
      >
        <ZipangAdvModal
          close={() => {
            setCloseDialog(false);
            trialModalHandler();
          }}
        />
      </StyledModal>
    );
  };
  return (
    <StyledHeroContainer isMobile={isMobile}>
      <StyledHeroCenterContainer>
        <StyledHeroBannerText
          align={isMobile ? "center" : slide?.textAlign}
          isMobile={isMobile}
        >
          <div>{slide?.heading1}</div>
          <div>{slide?.heading2}</div>
        </StyledHeroBannerText>
        <StyledHeroBannerSubText
          align={isMobile ? "center" : slide?.textAlign}
          isMobile={isMobile}
        >
          {slide?.subHeading}
        </StyledHeroBannerSubText>
        <StyledHeroButtonsContainer
          isMobile={isMobile}
          container
          direction={isMobile ? "column" : "row"}
          justifyContent={isMobile ? "center" : slide?.buttonAlign}
          alignItems="center"
        >
          {slideConfig[slideNumber]?.showButtons && (
            <>
              <ButtonComp
                isMobile={isMobile}
                wide
                widePadding
                type="contained-black"
                onClick={handleSellDiamonds}
              >
                {t("common:header:buttons:sell")}
              </ButtonComp>
              <ButtonComp
                isMobile={isMobile}
                onClick={toggleLoginPopup}
                wide
                widePadding
                type="contained-dark"
              >
                {t("common:header:buttons:buy")}
              </ButtonComp>
            </>
          )}
          {slideNumber === 0 && (
            <ButtonComp
              isMobile={isMobile}
              wide
              widePadding
              type="contained-black"
              onClick={handleClick}
            >
              {t("common:createNow")}
            </ButtonComp>
          )}
        </StyledHeroButtonsContainer>
        <StyledArrowDownContainer isMobile={isMobile} onClick={handleGoToDown}>
          <ArrowDown filled={slideNumber === 0} />
        </StyledArrowDownContainer>
      </StyledHeroCenterContainer>
      <StyledCarouselContainer>
        <GenericCarousel
          navButtonsAlwaysVisible={false}
          indicators={heroImages.length > 1}
          onChange={handleCarouselSlideChange}
        >
          {heroImages.map((item, i) => (
            <StyledImage
              key={i}
              src={item.src}
              mobileSrc={item.mobileSrc}
              alt={item.alt}
            />
          ))}
        </GenericCarousel>
      </StyledCarouselContainer>
      {advDialog && handleAdvModal()}
    </StyledHeroContainer>
  );
};

export default LandingHero;
