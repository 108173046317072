import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DialogProvider } from "../../common/elements/Dialog/Dialog";
import Colors from "../../common/materials/colors";
import Testimonials, { MobileTestimonials } from "./Testimonials";
import Footer from "../LandingPage/Footer/Footer";
import Header from "../LandingPage/Header/Header";
import Section from "../LandingPage/Section/Section";
import {
  LandingPagePlayFairFont,
  StyledImageCommonComponent,
  StyledImageCommonContainer,
} from "../LandingPage/StyledComponents";
import WhyUs from "./WhyUs";
import ScrollFix from "../../components/ScrollFix/ScrollFix";
import ScrollSpy from "../../components/ScrollFix/ScrollSpy";
import {
  StyledArrow,
  StyledCircularImg,
  StyledLabel,
  StyledMediaText,
  StyledMobTeamCircularContainer,
  StyledMobTeamPaper,
  StyledPaper,
  StyledSubLabel,
  StyledTeamCircularContainer,
  StyledTeamContainer,
  StyledTeamPaper,
} from "./StyledComponent";
import Arrow from "../LandingPage/Static/Icons/Arrow";
import { removeRedirectPath } from "../LandingPage/Utils";
import { Typography } from "@mui/material";

const useStyles = makeStyles({
  noBottomPadding: {
    paddingBottom: "0 !important",
  },
  extraPadding: {
    padding: "4em 0 !important",
  },
});

const incubators = [
  "https://ds29nc3ljdjce.cloudfront.net/500_dark_blue.png",
  "https://ds29nc3ljdjce.cloudfront.net/gfs_400x400.jpg",
  "https://ds29nc3ljdjce.cloudfront.net/she_loves_tech.png",
  "https://ds29nc3ljdjce.cloudfront.net/stai.jpg",
];

const recognition = {
  imageUrl: "/images/mediaSection/interCon.jpg",
  link: "",
  subheading: "Recognition",
  heading: "Intercon 20/21 Award Nominee",
};

const media = [
  {
    imageUrl: "/images/mediaSection/magazines-1.png",
    link: "/pdf/japan_precious.pdf",
    subheading: "Media Coverage",
    heading: "Japan Precious Summer 2021",
  },
  {
    imageUrl: "/images/mediaSection/magazines-2.png",
    link: "/pdf/jewellery.pdf",
    subheading: "Media Coverage",
    heading: "Jewelrist 2021, Vol. 158",
  },
  {
    imageUrl: "/images/mediaSection/magazines-3.png",
    link: "/pdf/myauctions_mrs.pdf",
    subheading: "Media Coverage",
    heading: "Jewelrist 2021, Vol. 158",
  },
];

const teamMemberArr = [
  {
    imageUrl: "/images/team_member/Monika_Jain.jpeg",
    subheading: "MONIKA JAIN",
    heading: "Founder and CEO",
  },
  {
    imageUrl: "/images/team_member/Pardeep_Sharma.jpeg",
    subheading: "PRADEEP SHARMA",
    heading: "CTO",
  },
  {
    imageUrl: "/images/team_member/Kanishk_Choraria.jpeg",
    subheading: "KANISHK CHORARIA",
    heading: "COO",
  },
  {
    imageUrl: "/images/team_member/Paridhi_chordia.jpeg",
    subheading: "PARIDHI CHORDIA",
    heading: "Business Analyst",
  },
  {
    imageUrl: "/images/team_member/Harshit_Rastogi.jpeg",
    subheading: "HARSHIT RASTOGI",
    heading: "Backend Developer",
  },
  {
    imageUrl: "/images/team_member/Shubham_kumar.jpeg",
    subheading: "SHUBHAM KUMAR",
    heading: "Frontend & App Developer",
  },
  {
    imageUrl: "/images/team_member/Kanika_Agarwal.jpeg",
    subheading: "KANIKA AGARWAL",
    heading: "Quality Analyst",
  },
  {
    imageUrl: "/images/team_member/paresh.png",
    subheading: "PARESH",
    heading: "Digital Marketing",
  },
];

const AboutUs = (props: any) => {
  const { t } = props;
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  useEffect(removeRedirectPath, []);

  const scrollContainer: any = document.getElementById('scroll-container');
  let isMouseDown = false;
  let startX: any;
  let startScrollLeft: any;

  if (scrollContainer !== null) {
    scrollContainer.addEventListener('mousedown', (e: any) => {
      isMouseDown = true;
      startX = e.pageX - scrollContainer.offsetLeft;
      startScrollLeft = scrollContainer.scrollLeft;
      scrollContainer.classList.add('dragging');
    });

    document.addEventListener('mouseup', () => {
      isMouseDown = false;
      scrollContainer.classList.remove('dragging');
    });

    document.addEventListener('mousemove', (e: any) => {
      if (!isMouseDown) return;
      e.preventDefault();
      const x = e.pageX - scrollContainer.offsetLeft;
      const walk = (x - startX) * 2; // Adjust scrolling speed
      scrollContainer.scrollLeft = startScrollLeft - walk;
    });
  }

  return (
    <DialogProvider>
      <ScrollFix />
      <ScrollSpy />
      <Grid container className="mainContainer">
        <Header greyBg />
        <Section
          container
          color={Colors.lightGrey}
          className={classes.noBottomPadding}
        >
          <Grid xs={12} item style={{ color: Colors.lightBlue }}>
            <Typography>{t("common:subHeader:buttons:aboutUs")}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} container direction="column">
            <Typography variant="h2">
              <LandingPagePlayFairFont>
                {t("common:aboutUs:newPage:heading")}
                <br />
                {t("common:aboutUs:newPage:heading1")}
              </LandingPagePlayFairFont>
            </Typography>
            <Typography variant="h6">
              {t("common:aboutUs:newPage:subheading")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent="flex-end">
            {isMobile ? null : (
              <StyledImageCommonContainer
                style={{
                  minWidth: "375px",
                  margin: 0,
                  transform: "translate3d(1em, 2em, 0em)",
                  display: "inline-block",
                  border: `1px solid ${Colors.darkBlue}`,
                }}
              >
                <StyledImageCommonComponent
                  style={{ transform: "translate3d(-1em, -1em, 0em)" }}
                  src="https://ds29nc3ljdjce.cloudfront.net/about-us-header.png"
                />
              </StyledImageCommonContainer>
            )}
          </Grid>
        </Section>
        {isMobile && (
          <StyledImageCommonContainer
            minHeight={"375px"}
            style={{
              margin: "0",
              paddingTop: "2em",
              background: Colors.lightGrey,
              width: "100vw",
            }}
          >
            <StyledImageCommonComponent
              style={{ width: "100%" }}
              src="https://ds29nc3ljdjce.cloudfront.net/about-us-header.png"
            />
          </StyledImageCommonContainer>
        )}
        {!isMobile && (
          <Section
            isMobile={isMobile}
            id="team"
            className={classes.extraPadding}
            label={"Meet Our Team"}
            container
          >
            <StyledTeamContainer className="invisible-scrollbar" id="scroll-container">
              {teamMemberArr.map((media) => (
                <StyledTeamPaper>
                  <StyledTeamCircularContainer>
                    <StyledCircularImg src={media.imageUrl} />
                  </StyledTeamCircularContainer>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "5px",
                      padding: "5px",
                      alignItems: "center",
                      width: '70%'
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={700}
                      style={{
                        textAlign: "center",
                        whiteSpace: 'break-spaces'
                      }}
                    >
                      {media.subheading}
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={100}>
                      {media.heading}
                    </Typography>
                  </div>
                </StyledTeamPaper>
              ))}
            </StyledTeamContainer>
          </Section>
        )}

        {isMobile && (
          <Section
            isMobile={isMobile}
            id="team"
            className={classes.extraPadding}
            label={"Meet Our Team"}
            container
          >
            <Grid
              container
              wrap="wrap"
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {teamMemberArr.map((media) => (
                <Grid item={true} xs={6} sm={6} md={6}>
                  <StyledMobTeamPaper>
                    <StyledMobTeamCircularContainer>
                      <StyledCircularImg src={media.imageUrl} />
                    </StyledMobTeamCircularContainer>
                    <div style={{ width: "80%", display: "flex", justifyContent: 'center' }}>
                      <StyledLabel
                      >
                        {media.subheading}
                      </StyledLabel>
                    </div>
                    <StyledSubLabel>
                      {media.heading}
                    </StyledSubLabel>
                  </StyledMobTeamPaper>
                </Grid>
              ))}
            </Grid>
          </Section>
        )}


        <Section
          isMobile={isMobile}
          className={classes.extraPadding}
          label={t("common:whyus")}
          color={Colors.lightGrey}
          container
          id="whyus"
        >
          <WhyUs t={t} />
        </Section>
        <Section
          isMobile={isMobile}
          id="testimonials"
          className={classes.extraPadding}
          label={t("common:subHeader:buttons:testimonials")}
          container
        >
          {isMobile ? <MobileTestimonials /> : <Testimonials />}
        </Section>
        <Section
          isMobile={isMobile}
          id="media"
          className={classes.extraPadding}
          label={t("common:subHeader:buttons:media")}
          container
        >
          <Grid container wrap="wrap">
            {media.map((media) => (
              <StyledPaper
                onClick={() => {
                  media.link && window.open(media.link, "_blank");
                }}
              >
                <StyledImageCommonContainer
                  minHeight="150px"
                  style={{ height: "150px", width: "150px" }}
                >
                  <StyledImageCommonComponent
                    src={media.imageUrl}
                    style={{ borderRadius: "2em" }}
                  />
                </StyledImageCommonContainer>
                <StyledMediaText>
                  <StyledArrow>
                    <Arrow direction="right" filled={true} />
                  </StyledArrow>
                  <span>{media.subheading}</span>
                  <p className="fontBold">{media.heading}</p>
                </StyledMediaText>
              </StyledPaper>
            ))}
          </Grid>
        </Section>
        <Section
          isMobile={isMobile}
          id="incubators"
          className={classes.extraPadding}
          label={t("common:subHeader:buttons:incubator")}
          container
        >
          <Grid container>
            {incubators.map((image) => (
              <StyledImageCommonContainer
                minHeight="150px"
                style={{ height: "150px", width: "150px" }}
              >
                <StyledImageCommonComponent src={image} />
              </StyledImageCommonContainer>
            ))}
          </Grid>
        </Section>
      </Grid>
      <Footer />
    </DialogProvider>
  );
};

export default AboutUs;
