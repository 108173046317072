import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { DialogProvider, useDialog } from "../../common/elements/Dialog/Dialog";
import Colors from "../../common/materials/colors";
import Footer from "../LandingPage/Footer/Footer";
import Header from "../LandingPage/Header/Header";
import Section from "../LandingPage/Section/Section";
import {
  LandingPagePlayFairFont,
  StyledImage,
  StyledImageCommonComponent,
  StyledImageCommonContainer,
  StyledQRBox,
  StyledQRimageContainer,
} from "../LandingPage/StyledComponents";
import Button from "../../common/elements/Button";
import {
  StyledInputLabel,
  StyledMessage,
  StyledUploadInput,
} from "./StyledComponents";
import { postSellPageData } from "./Services";
import Cross from "../LandingPage/Static/Icons/Cross";
import ReCaptcha from "../kyc/components/ReCaptcha";
import ScrollFix from "../../components/ScrollFix/ScrollFix";
import {
  redirectToOtherDomain,
  removeRedirectPath,
} from "../LandingPage/Utils";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import ProductList from "../LandingPage/ProductCard/ProductList";
import AdsList from "../LandingPage/Ads/AdsList";
import { IProductCardProps } from "../LandingPage/ProductCard/ProductCard";
import { getEndedProducts } from "../LandingPage/Services";

const useStyles = makeStyles({
  asterisk: {
    color: Colors.red,
  },
  noBottomPadding: {
    paddingBottom: "0 !important",
  },
  subheading: {
    fontWeight: "bold",
    paddingTop: "2em",
    paddingLeft: "1em",
    paddingRight: 0,
  },
  paddingLeft: {
    paddingLeft: "1em",
  },
  paddingLeftLite: {
    paddingLeft: "0.5em",
  },
  noPadding: {
    padding: "0",
  },
});

const useSuccessStyles = makeStyles({
  container: {
    padding: "4em 1em",
    background: Colors.lightGrey,
    position: "relative",
  },
  cross: {
    position: "absolute",
    right: "1em",
    top: "1em",
    margin: 0,
  },
});

const UploadIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
    <path d="M6.625 19q-.7 0-1.162-.462Q5 18.075 5 17.375V15h1v2.375q0 .25.188.437.187.188.437.188h10.75q.25 0 .437-.188.188-.187.188-.437V15h1v2.375q0 .7-.462 1.163-.463.462-1.163.462Zm4.875-3.775v-8.65L9.175 8.9l-.725-.7L12 4.65l3.55 3.55-.725.7L12.5 6.575v8.65Z" />
  </svg>
);

const Modal = ({ message }: { message: string }) => {
  const { t } = useTranslation();
  const successStyles = useSuccessStyles();
  const [createDialog, closeDiage] = useDialog();

  const handleSuccess = (message: string) => {
    createDialog({
      children: (
        <div className={successStyles.container}>
          <Button
            type=""
            onClick={closeDiage}
            className={successStyles.cross}
            noMargin
          >
            <Cross filled />
          </Button>
          <p>
            <Typography variant="h4" align="center">
              {t("common:sellPages:success")}
            </Typography>
          </p>
        </div>
      ),
      fullWidth: true,
      open: true,
    });
  };
  useEffect(() => {
    message && handleSuccess(message);
  }, [message]);
  return <div />;
};

let filesObjects: { [key: string]: File[] } = {
  photo: [],
  certificate: [],
  productExcel: [],
};

const SellPage = (props: any) => {
  const { t } = props;
  const isMobile = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  const [errorStates, setErrorStates] = useState({} as any);
  const [state, setState] = useState({} as any);
  const [message, setMessage] = useState("" as any);
  const [errorMessage, setErrorMessage] = useState("" as any);
  const [checkState, setCheckState] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [notARobot, setNotARobot] = useState(false);

  const [files, setFiles] = useState({
    photo: [],
    certificate: [],
    productExcel: [],
  } as any);

  const formRef = useRef(null);

  useEffect(removeRedirectPath, []);

  const validation = (formData: {
    [x: string]: any;
    email?: any;
    firstName?: any;
    lastName?: any;
    phone?: any;
  }) => {
    const errors = {} as any;
    setErrorMessage(null);
    const email = new RegExp(
      /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,20}/g
    ).test(formData.email.trim());
    var digits = /^(\+){0,1}[0-9]{8,15}$/;
    if (formData.firstName === "") {
      setErrorMessage(t("common:register:errorMessage:onfirstName"));
    } else if (formData.lastName === "") {
      setErrorMessage(t("common:register:errorMessage:onlastName"));
    } else if (!formData.productDescription) {
      setErrorMessage(t("trans:productDescriptionError"));
    } else if (email !== true) {
      errors.email = true;
      setErrorMessage(t("common:register:errorMessage:onEmail"));
    } else if (formData.phone.length < 10) {
      errors.phone = true;
      setErrorMessage(t("common:register:errorMessage:phoneNumber"));
    } else if (!formData.phone.match(digits)) {
      errors.phone = true;
      setErrorMessage(t("common:register:errorMessage:onMobileNumber"));
    } else if (!formData.notARobot) {
      setErrorMessage(t("trans:pleaseVerifyCaptchs"));
    }
    setErrorStates(errors);
    return errors;
  };

  const handleFormSubmit = async () => {
    const form = formRef.current;
    if (!form) {
      return;
    }
    const formData = new FormData(form);
    const values = Object.fromEntries(formData.entries()) as {
      [k: string]: any;
    };
    const errors = {} as any;
    for (const field in values) {
      const elem = form[field] as any;
      if (elem?.type === "text") {
        const required = elem.getAttribute("required") === "";
        if (required && !elem.value) {
          errors[field] = true;
        }
      } else if (elem?.type === "checkbox") {
        formData.append(field, elem.value === "" ? "true" : "false");
      }
    }

    if (!notARobot) {
      errors["notARobot"] = true;
    }

    values["notARobot"] = notARobot;

    setErrorStates(errors);
    const validationErrors = validation(values);
    let hasErrors = false;
    for (let error in { ...errors, ...validationErrors }) {
      hasErrors = true;
      break;
    }
    if (hasErrors) {
      return;
    }

    if (!hasErrors) {
      const requiredFormat = new FormData();
      requiredFormat.append(
        "tempDetails",
        JSON.stringify({
          ...values,
          visitForEstimation: values["visitForEstimation"] === "",
          sendEstimationKit: values["sendEstimationKit"] === "",
          requestCouponForCglLab: values["requestCouponForCglLab"] === "",
          isJewelleryType: values["isJewelleryType"] === "Yes",
          estimatedAmount: Number(values["estimatedAmount"]),
          productExcel: undefined,
          photo: undefined,
          certificate: undefined,
        })
      );
      for (let file of filesObjects["productExcel"]) {
        requiredFormat.append("productExcel", file);
      }

      for (let file of filesObjects["photo"]) {
        requiredFormat.append("photo", file);
      }

      for (let file of filesObjects["certificate"]) {
        requiredFormat.append("certificate", file);
      }

      setIsLoading(true);
      const { response, status } = await postSellPageData(requiredFormat);
      setIsLoading(false);
      setMessage(response.message);

      if (status === 200) {
        resetFormFields(form);
      }
    }
  };

  const resetFormFields = (form: any) => {
    setFiles({
      photo: [],
      certificate: [],
      productExcel: [],
    });
    (form as any).reset();
    setCheckState({
      visitForEstimation: false,
      sendEstimationKit: false,
      requestCouponForCglLab: false,
    });
    setErrorStates({});
    (window["grecaptcha" as any] as any).reset();
    filesObjects = {
      photo: [],
      certificate: [],
      productExcel: [],
    };
  };

  const handleFileChange = (e: any) => {
    const fieldName = e.target.name as string;
    const _files = e.target.files;
    const newFileNames = [];
    for (let file of _files) {
      newFileNames.push(file.name);
    }
    files[fieldName] = [...files[fieldName], ...newFileNames];
    filesObjects[fieldName] = _files;
    setFiles({ ...files });
  };

  const handleNotARobot = () => {
    setNotARobot(!notARobot);
  };

  const handleCheckBoxClick = (e: any) => {
    const targetName = e.target.name;
    setCheckState({
      ...checkState,
      [targetName]: !checkState[targetName],
    });
  };

  const photoRef = useRef(null) as React.MutableRefObject<any>;
  const productExcelRef = useRef(null) as React.MutableRefObject<any>;
  const certificateRef = useRef(null) as React.MutableRefObject<any>;

  const [previousAuctionList, setPreviousAcutctionsList] = useState(
    [] as IProductCardProps[]
  );

  const [adImageSources, setAdImageSources] = useState(
    [] as { link: string; imgSrc: string }[]
  );

  useEffect(() => {
    getProductsList();
  }, []);

  const getProductsList = async () => {
    const endedProducts = await getEndedProducts();
    if (!endedProducts?.results) {
      return;
    }
    if (endedProducts.results.length !== 0) {
      setPreviousAcutctionsList(
        endedProducts.results.slice(0, 3) as IProductCardProps[]
      );
    }
  };
  return (
    <DialogProvider>
      <Grid container className="mainContainer">
        <ScrollFix />
        <Header greyBg />
        <Modal message={message} />
        <Section
          container
          color={Colors.lightGrey}
          className={classes.noBottomPadding}
        >
          <Grid xs={12} item style={{ color: Colors.lightBlue }}>
            <Typography>{t("common:sellPages:title")}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} container direction="column">
            <Typography variant="h2">
              <LandingPagePlayFairFont>
                {t("common:sellPages:heading")}
                <br />
                {t("common:sellPages:heading1")}
              </LandingPagePlayFairFont>
            </Typography>
            <Typography variant="h6" className={classes.subheading}>
              {t("common:sellPages:subheading")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            container
            justifyContent={isMobile ? "center" : "flex-end"}
          >
            {isMobile ? null : (
              <StyledImageCommonContainer
                style={{
                  minWidth: "375px",
                  margin: 0,
                  transform: "translate3d(1em, 2em, 0em)",
                  display: "inline-block",
                  border: `1px solid ${Colors.darkBlue}`,
                }}
              >
                <StyledImageCommonComponent
                  style={{ transform: "translate3d(-1em, -1em, 0em)" }}
                  src="https://ds29nc3ljdjce.cloudfront.net/MyAuctions-SYD-hero-mobile.png"
                />
              </StyledImageCommonContainer>
            )}
          </Grid>
        </Section>
        {isMobile && (
          <StyledImageCommonContainer
            minHeight={"544px"}
            style={{
              margin: "0",
              paddingTop: "2em",
              background: Colors.lightGrey,
              width: "100vw",
            }}
          >
            <StyledImageCommonComponent
              style={{ width: "100%" }}
              src="https://ds29nc3ljdjce.cloudfront.net/MyAuctions-SYD-hero-mobile.png"
            />
          </StyledImageCommonContainer>
        )}
        <Section>
          <Grid
            container
            justifyContent={isMobile ? "center" : "flex-start"}
            direction="row"
          >
            <Grid xs={12} item>
              <Typography variant="h6" align={isMobile ? "center" : "left"}>
                {t("common:sellPages:areYouBuss")}
              </Typography>
              {/* <Typography paragraph align={isMobile ? "center" : "left"}>
                {t("common:sellPages:visitSell")}
              </Typography> */}
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              container
              justifyContent={isMobile ? "center" : "flex-start"}
            >
              <Button
                type="contained-dark"
                noMargin
                onClick={() => redirectToOtherDomain("auctioneer")}
              >
                {t("common:sellPages:visitSell")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                paragraph
                align={isMobile ? "center" : "left"}
                className={classes.subheading}
              >
                <LandingPagePlayFairFont>
                  {t("common:sellPages:or")}
                </LandingPagePlayFairFont>
              </Typography>
            </Grid>
          </Grid>
          <form ref={formRef} noValidate autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    error={errorStates["firstName"]}
                    required
                    InputLabelProps={{
                      classes,
                    }}
                    variant="outlined"
                    label={t("common:register:labels:firstName")}
                    type="text"
                    name="firstName"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    error={errorStates["lastName"]}
                    required
                    variant="outlined"
                    label={t("common:register:labels:lastName")}
                    type="text"
                    name="lastName"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    error={errorStates["phone"]}
                    required
                    variant="outlined"
                    label={t("common:register:labels:phone")}
                    type="text"
                    name="phone"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    error={errorStates["email"]}
                    required
                    variant="outlined"
                    label={t("common:register:labels:email")}
                    type="text"
                    name="email"
                  />
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <Typography
                  paragraph
                  className={classes.subheading}
                  align={isMobile ? "center" : "left"}
                >
                  {t("common:sellPages:desOfStone")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    error={errorStates["productDescription"]}
                    required
                    variant="outlined"
                    label={t("common:sellPages:prodDesc")}
                    type="text"
                    name="productDescription"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={errorStates["isJewelleryType"]}
                >
                  <InputLabel>{t("common:sellPages:setInJewel")}</InputLabel>
                  <Select
                    label={t("common:sellPages:setInJewel")}
                    labelId={t("common:sellPages:setInJewel")}
                    name={"isJewelleryType"}
                    value={state["isJewelleryType"]}
                  //   onChange={onChange}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <StyledUploadInput onClick={() => photoRef.current?.click()}>
                  <StyledInputLabel>
                    {files["photo"].join(", ") ||
                      t("common:sellPages:uploadPhoto")}
                  </StyledInputLabel>
                  {UploadIcon}
                  <input
                    accept="image/png,image/jpeg"
                    ref={photoRef}
                    hidden
                    id="photo"
                    name="photo"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                  />
                </StyledUploadInput>
              </Grid>
              <Grid item xs={12} sm={3}>
                <StyledUploadInput
                  onClick={() => certificateRef.current?.click()}
                >
                  <StyledInputLabel>
                    {files["certificate"].join(", ") ||
                      t("common:sellPages:uploadCert")}
                  </StyledInputLabel>
                  {UploadIcon}
                  <input
                    accept="image/png,image/jpeg"
                    ref={certificateRef}
                    hidden
                    id="certificate"
                    name="certificate"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                  />
                </StyledUploadInput>
              </Grid>
              <Grid item xs={12} sm={3}>
                <StyledUploadInput
                  onClick={() => productExcelRef.current?.click()}
                >
                  <StyledInputLabel>
                    {files["productExcel"].join(", ") ||
                      t("common:sellPages:bulkUpload")}
                  </StyledInputLabel>
                  {UploadIcon}
                  <input
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    ref={productExcelRef}
                    hidden
                    id="productExcel"
                    name="productExcel"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                  />
                </StyledUploadInput>
              </Grid>
              <Grid xs={12}>
                <Typography
                  paragraph
                  className={classes.subheading}
                  align={isMobile ? "center" : "left"}
                >
                  {t("common:sellPages:estimation")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    error={errorStates["estimatedCompanyName"]}
                    variant="outlined"
                    label={t("common:sellPages:companyName")}
                    type="text"
                    name="estimatedCompanyName"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    error={errorStates["estimatedAmount"]}
                    variant="outlined"
                    label={t("common:sellPages:enterValue")}
                    type="number"
                    name="estimatedAmount"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={6} lg={6}>
                <StyledQRBox>
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ textAlign: "center", fontWeight: 700 }}
                  >
                    または、GIA認定宝石鑑定士に直接お問い合わせください。
                  </Typography>
                  <StyledQRimageContainer container spacing={1}>
                    <Grid xs={12} sm={4} md={4} xl={4} lg={4}>
                      <StyledImage src="https://ds29nc3ljdjce.cloudfront.net/line.png" />
                    </Grid>
                    <Grid xs={12} sm={4} md={4} xl={4} lg={4}>
                      <StyledImage src="https://ds29nc3ljdjce.cloudfront.net/wechat.png" />
                    </Grid>
                    <Grid xs={12} sm={4} md={4} xl={4} lg={4}>
                      <StyledImage src="https://ds29nc3ljdjce.cloudfront.net/whatsapp.png" />
                    </Grid>
                  </StyledQRimageContainer>
                </StyledQRBox>
              </Grid>
              <Grid container style={isMobile ? { padding: "0 1em" } : {}}>
                <Typography paragraph className={classes.subheading}>
                  {t("common:sellPages:select")}
                </Typography>
                <Grid xs={12} className={classes.paddingLeftLite}>
                  <Checkbox
                    name="visitForEstimation"
                    id="visitForEstimation"
                    checked={!!checkState["visitForEstimation"]}
                    onClick={handleCheckBoxClick}
                  />
                  {t("common:sellPages:options:1")}
                </Grid>
                <Grid xs={12} className={classes.paddingLeftLite}>
                  <Checkbox
                    name="sendEstimationKit"
                    id="sendEstimationKit"
                    checked={!!checkState["sendEstimationKit"]}
                    onClick={handleCheckBoxClick}
                  />
                  {t("common:sellPages:options:2")}
                </Grid>
                <Grid xs={12} className={classes.paddingLeftLite}>
                  <Checkbox
                    name="requestCouponForCglLab"
                    id="requestCouponForCglLab"
                    checked={!!checkState["requestCouponForCglLab"]}
                    onClick={handleCheckBoxClick}
                  />
                  {t("common:sellPages:options:3")}
                </Grid>
                <Grid xs={12} sm={6} className={classes.paddingLeft}>
                  <Typography paragraph>
                    {t("common:sellPages:options:note")}
                  </Typography>
                  <Typography paragraph>
                    {t("common:sellPages:options:note2")}
                  </Typography>
                </Grid>
                <Grid container>
                  <StyledMessage>
                    <p>{errorMessage}</p>
                  </StyledMessage>
                </Grid>
                <Grid
                  className={classes.paddingLeft}
                  item
                  container
                  alignItems="center"
                  justifyContent={isMobile ? "center" : "flex-start"}
                >
                  <ReCaptcha setIsVerfied={handleNotARobot} />
                  <Button
                    type="contained-dark"
                    wide
                    onClick={handleFormSubmit}
                    disabled={isLoading}
                  >
                    {t("common:kycFrom:submit")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Section>
        <Grid container xs={12}>
          {previousAuctionList.length && !isMobile ? (
            <Section container>
              <Grid xs={12} item>
                <Section
                  className={classes.noPadding}
                  container
                  label={t("common:prevAuc")}
                  pageLink="/auctionslist/previous"
                >
                  <ProductList
                    hideBidButton
                    fixedHeightCards
                    isMobile={isMobile}
                    productsList={previousAuctionList}
                    ads={[]}
                    isZipang={false}
                    isLandingPage={true}
                  />
                </Section>
              </Grid>
            </Section>
          ) : null}
          {isMobile && previousAuctionList.length ? (
            <Section
              className={classes?.noPadding}
              container
              label={t("common:prevAuc")}
              pageLink="/auctionslist/previous"
            >
              <ProductList
                hideBidButton
                isMobile
                productsList={previousAuctionList}
                ads={[]}
                isZipang={false}
                isLandingPage={true}
              />
            </Section>
          ) : null}
        </Grid>
      </Grid>
      <Footer />
    </DialogProvider>
  );
};

export default SellPage;
